document.addEventListener('DOMContentLoaded', function () {
    Fancybox.bind('[data-fancybox]');
    gsap.registerPlugin(ScrollTrigger);

    ScrollTrigger.create({
        start: "top",
        end: "bottom",
        toggleClass: {targets: '.js-header', className: 'header-collapsed'},
    });

    gsap.to('.parallax-items [data-speed]', {
        y: (i, el) => parseFloat(el.getAttribute('data-speed')) * ScrollTrigger.maxScroll(window),
        ease: "none",
        scrollTrigger: {
            trigger: ".parallax-items",
            start: 0,
            invalidateOnRefresh: true,
            scrub: 5,
        }
    });

    gsap.utils.toArray('.anim-fadeIn').forEach(item => {
        gsap.set(item, {
            opacity: 0,
        });

        let fadeIn = () => gsap.to(item, {
            opacity: 1,
            duration: 1,
            ease: "none",
        });

        let fadeOut = () => gsap.to(item, {
            opacity: 0,
            duration: 1,
            ease: "none",
        });

        ScrollTrigger.create({
            trigger: item,
            start: "top 95%",
            end: "bottom 5%",
            onEnter: fadeIn,
            onLeave: fadeOut,
            onEnterBack: fadeIn,
            onLeaveBack: fadeOut,
        });
    });

    var welcomeSlider = new Swiper('.js-welcome-slider .swiper', {
        spaceBetween: 0,
        speed: 1000,
        loop: true,
        autoplay: {
            delay: 3000,
        },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
    });

    document.querySelectorAll('[data-bs-toggle="collapse"]').forEach(itemCollapse => {
        tmpTarget = itemCollapse.dataset.bsTarget;
        document.querySelector(tmpTarget).addEventListener('hidden.bs.collapse', Event => {
            ScrollTrigger.refresh(true);
        });
        document.querySelector(tmpTarget).addEventListener('shown.bs.collapse', Event => {
            ScrollTrigger.refresh(true);
        });
    });
});

document.addEventListener('lazyloaded', function () {
    ScrollTrigger.refresh(true);
});